import React, { useMemo, useState } from "react";

import { Badge, Button } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { calculateExceptions } from "../helpers/workTimes";
import EditWorkTimeModal from "./EditWorkTimeModal";

const { useAuth, useWorkOrderDetails } = data;

const { Icon, TooltipItem } = components;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const JOB_DETAILS_TAB = "JOB_DETAILS_TAB";

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";
const WORK_TIMES_PACKAGE = "worktimes";

const CREWS_PACKAGE = "crews";

const EmployeeCrewRow = ({ employeeCrew, workTimes, onRefresh }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [editModal, setEditModal] = useState();

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    WORK_TIMES_PACKAGE,
    START_END_WORK_TIME_SETTING
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const isReadOnly = workOrderDetails.activeTab === JOB_DETAILS_TAB;

  const wTimes = useMemo(() => {
    const workTimesByType = workTimes
      .sort((x, y) => y.workTimeType.order - x.workTimeType.order)
      .reduce((p, c) => {
        const isInProgress = c.startTime && !c.endTime;
        const exceptionHours = calculateExceptions(c);
        const item = {
          ...c,
          exceptionHours,
          isInProgress,
        };
        if (!p[c.workTimeType.shortName]) {
          p[c.workTimeType.shortName] = [item];
        } else {
          p[c.workTimeType.shortName].push(item);
        }
        return p;
      }, {});
    return Object.keys(workTimesByType)
      .map((workTimeType, index) => {
        const typeWorkTimes = workTimesByType[workTimeType];
        if (!typeWorkTimes) {
          return false;
        }
        const isInProgress = Boolean(
          typeWorkTimes.find((wt) => wt.isInProgress)
        );
        const hours = isInProgress
          ? "In Progress"
          : sharedHelper.formatDecimal(
              typeWorkTimes.reduce((p, c) => p + c.hours + c.exceptionHours, 0)
            );
        return (
          <div
            key={workTimeType}
            className={`d-flex justify-content-${
              isReadOnly ? "end" : "center"
            } align-items-center flex-shrink-0`}
          >
            {index !== 0 ? <span className="mx-1">/</span> : null}
            <span>{`${hours} ${
              isInProgress ? "" : "of"
            } ${workTimeType}`}</span>
            <TooltipItem
              id={`${workTimeType}-tooltip`}
              title={sharedHelper.timeEntryToolTipContent(
                typeWorkTimes,
                true,
                startEndTimeSettingEnabled
              )}
              opacity={1}
            >
              <Icon
                name="info"
                className="text-muted ms-1"
                style={{ paddingBottom: "1px" }}
                data-testid={`${workTimeType}-info-icon`}
              />
            </TooltipItem>
          </div>
        );
      })
      .filter(Boolean);
  }, [workTimes, startEndTimeSettingEnabled, isReadOnly]);

  return (
    <div className="d-flex align-items-center justify-content-between p-3 border-radius-default box-shadow-lg my-3">
      <div className="col-4 d-flex align-items-center flex-grow-1 pe-3">
        <span>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</span>
        <div className="text-muted d-flex align-items-center ms-2">
          {!directAssignationEnabled && employeeCrew.isLead ? (
            <Badge color="primary" className="me-2">
              Crew Lead
            </Badge>
          ) : null}
          <Badge color="primarylight" className="text-primary">
            {employeeCrew.role.name}
          </Badge>
        </div>
      </div>
      {workTimes.length ? (
        <>
          <div
            className={`d-flex col-6 justify-content-${
              isReadOnly ? "end" : "center"
            } align-items-center`}
          >
            {wTimes}
          </div>
          {!isReadOnly ? (
            <div className="col-2 d-flex justify-content-end">
              <Button
                size="sm"
                color="none"
                className="mx-2 text-primary"
                onClick={() => setEditModal(true)}
              >
                Edit
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div
            className={`col-6 text-${isReadOnly ? "end" : "center"} text-muted`}
          >
            No time
          </div>
          {!isReadOnly ? (
            <div className="col-2 d-flex justify-content-end"></div>
          ) : null}
        </>
      )}
      {editModal ? (
        <EditWorkTimeModal
          workTimes={workTimes}
          employee={employeeCrew.employee}
          onClose={() => {
            setEditModal();
          }}
          onSubmit={() => {
            setEditModal();
            onRefresh();
          }}
        />
      ) : null}
    </div>
  );
};

export default EmployeeCrewRow;
