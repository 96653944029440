import React, { useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import moment from "moment";
import {
  useWorkOrderDetails,
  ACTIONS as WORK_ORDER_DETAILS_ACTIONS,
} from "../providers/workOrderDetailsProvider";
import Icon from "./Icon";

const SLIDES_TO_SHOW = 20;
const TRANSITION_SPEED = 250;

const getMonths = (setIndicatorMonths) => {
  const allItems = Array.from(document.getElementsByClassName("slick-slide"));
  const activeItems = Array.from(
    document.getElementsByClassName("slick-active")
  );
  const leftIndex =
    Math.min(...activeItems.map((item) => item.getAttribute("data-index"))) - 1;
  const rightIndex =
    Math.max(...activeItems.map((item) => item.getAttribute("data-index"))) + 1;
  const leftItem = allItems.find(
    (i) => parseInt(i.getAttribute("data-index")) === leftIndex
  );
  const rightItem = allItems.find(
    (i) => parseInt(i.getAttribute("data-index")) === rightIndex
  );
  if (leftItem && rightItem) {
    setIndicatorMonths({
      left: moment(leftItem.firstChild.firstChild.id).format("MMMM/YYYY"),
      right: moment(rightItem.firstChild.firstChild.id).format("MMMM/YYYY"),
    });
  }
};

const NextArrow = React.memo(({ onClick }) => (
  <div onClick={onClick} className="cursor-pointer ms-3 pt-4">
    <Icon name="chevron-right" className="text-primary rounded-circle fs-4" />
  </div>
));

const PrevArrow = React.memo(({ onClick }) => (
  <div onClick={onClick} className="cursor-pointer me-3 pt-4">
    <Icon name="chevron-left" className="text-primary rounded-circle fs-4" />
  </div>
));

const WorkOrderDateSelectorContainer = ({ items }) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [indicatorMonths, setIndicatorMonths] = useState({});

  const sliderRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(
    items.findIndex((item) => item.value === workOrderDetails.selectedDate)
  );

  const settings = useMemo(
    () => ({
      initialSlide: currentSlideIndex,
      infinite: true,
      arrows: true,
      focusOnSelect: true,
      centerMode: true,
      slidesToShow: SLIDES_TO_SHOW,
      speed: TRANSITION_SPEED,
      className: "center d-flex align-items-center justify-content-between",
      nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
      prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: SLIDES_TO_SHOW / 3,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: SLIDES_TO_SHOW / 2,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: SLIDES_TO_SHOW,
          },
        },
      ],
      afterChange: (current) => {
        setCurrentSlideIndex(current);
        setWorkOrderDetails({
          action: WORK_ORDER_DETAILS_ACTIONS.SELECT_DATE,
          payload: { date: items[current].value },
        });
      },
    }),
    [items, setWorkOrderDetails, currentSlideIndex]
  );

  useEffect(() => {
    const newIndex = items.findIndex(
      (item) => item.value === workOrderDetails.selectedDate
    );
    if (
      sliderRef.current &&
      newIndex !== -1 &&
      newIndex !== currentSlideIndex
    ) {
      setCurrentSlideIndex(newIndex);
      sliderRef.current.slickGoTo(newIndex);
    }
    getMonths(setIndicatorMonths);
  }, [workOrderDetails.selectedDate, items, currentSlideIndex]);

  const dates = useMemo(
    () =>
      items.map((item, index) => {
        const isSelected = index === currentSlideIndex;
        return (
          <div
            className="cursor-pointer border mt-4 mb-2 d-flex flex-column align-items-center border-white"
            key={item.value}
            id={item.value}
            data-testid={`date-item-${item.value}`}
          >
            <span
              className={`${
                isSelected
                  ? "text-white bg-primary"
                  : item.hasData
                  ? "text-muted border-white bg-primarylight"
                  : !item.isWoDate
                  ? "text-muted border-white"
                  : ""
              } border date rounded-circle px-2 py-1`}
            >
              {item.dateNumber}
            </span>
            <small className="text-muted text-uppercase">
              {item.dateLabel}
            </small>
          </div>
        );
      }),
    [items, currentSlideIndex]
  );

  return (
    <div className="dates-slider">
      <div className="position-relative w-100 d-flex align-items-center justify-content-between mt-1 mb-n3">
        <div
          style={{ top: "50%", zIndex: 0 }}
          className="w-100 mx-0 border-top position-absolute"
        ></div>
        <div
          className="small text-muted bg-white text-uppercase pe-2"
          style={{ zIndex: 1 }}
        >
          {indicatorMonths.left || ""}
        </div>
        <div
          className="small text-muted bg-white text-uppercase ps-2"
          style={{ zIndex: 1 }}
        >
          {indicatorMonths.right || ""}
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {dates}
      </Slider>
    </div>
  );
};

const WorkOrderDateSelector = ({ crewWorkDays }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const startDate = moment(workOrderDetails.workOrder.startDate);
  const endDate = moment(workOrderDetails.workOrder.endDate);

  const workOrderLength = endDate.diff(startDate, "days");
  const calendarItems = Math.max(workOrderLength * 4, 20); //4 times the WO length on each side of the calendar
  /*
    if the WO starts the 15 and ends the 25 (length 10):
      - We show 40 (10 * 4) days before the 15
      - We show 40 (10 * 4) days after the 25
      - Show min 20 days otherwise the component breaks
  */

  const items = [
    {
      value: startDate.format("YYYY-MM-DD"),
      hasData: Boolean(crewWorkDays[startDate.format("YYYY-MM-DD")]),
      isWoDate: startDate.isBetween(
        moment(workOrderDetails.workOrder.startDate),
        moment(workOrderDetails.workOrder.endDate),
        "day",
        "[]"
      ),
      dateNumber: startDate.format("DD"),
      dateLabel: startDate.format("ddd"),
    },
  ];

  for (let i = 1; i < calendarItems; i++) {
    const prevDate = moment(startDate).subtract(i, "days");
    items.unshift({
      value: prevDate.format("YYYY-MM-DD"),
      hasData: Boolean(crewWorkDays[prevDate.format("YYYY-MM-DD")]),
      isWoDate: prevDate.isBetween(
        moment(workOrderDetails.workOrder.startDate),
        moment(workOrderDetails.workOrder.endDate),
        "day",
        "[]"
      ),
      dateNumber: prevDate.format("DD"),
      dateLabel: prevDate.format("ddd"),
    });
    const nextDate = moment(startDate).add(i, "days");
    items.push({
      value: nextDate.format("YYYY-MM-DD"),
      hasData: Boolean(crewWorkDays[nextDate.format("YYYY-MM-DD")]),
      isWoDate: nextDate.isBetween(
        moment(workOrderDetails.workOrder.startDate),
        moment(workOrderDetails.workOrder.endDate),
        "day",
        "[]"
      ),
      dateNumber: nextDate.format("DD"),
      dateLabel: nextDate.format("ddd"),
    });
  }

  return <WorkOrderDateSelectorContainer items={items} />;
};

export default WorkOrderDateSelector;
