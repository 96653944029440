import { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSafetyTemplates, useDeleteTemplate, useDuplicateTemplate } from "../../../api/Safety.hooks";

const inspectionsStatus = [
  { name: "Published", id: "published" },
  { name: "Draft", id: "draft" },
];

export const filters = inspectionsStatus;

export const useListTemplateLogic = () => {
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);

  const navigate = useNavigate();

  const {
    data: inspectionTemplatesData,
    isLoading,
    get: getSafetyTemplates,
  } = useGetSafetyTemplates();

  const {
    mutate: deleteTemplate,
  } = useDeleteTemplate();

  const {
    mutate: duplicateTemplate,
  } = useDuplicateTemplate();

  const handleSearch = useCallback(
    ($event) => {
      setSearch($event.target.value);
    },
    [setSearch]
  );

  const handleSelectFilter = useCallback(
    (filter) => {
      setSelectedFilter(filter);
    },
    [setSelectedFilter]
  );

  const handleRefresh = useCallback(async () => {
    await getSafetyTemplates({ pageSize: 100 });
  }, [getSafetyTemplates]);

  const handleDeleteItem = useCallback(async (id) => {
    await deleteTemplate({ id });
    await handleRefresh();
  }, [deleteTemplate, handleRefresh]);

  const handleCopyItem = useCallback(async (id) => {
    await duplicateTemplate({}, { pathParams: { templateId: id } });
    await handleRefresh();
  }, [duplicateTemplate, handleRefresh]);

  const handleView = useCallback((id) => {
    navigate(`/safety/templates/view/${id}`);
  }, [navigate]);

  const handleEdit = useCallback((id) => {
    navigate(`/safety/templates/edit/${id}`);
  }, [navigate]);

  useEffect(() => {
    getSafetyTemplates({ pageSize: 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templates = useMemo(() => {
    if (!inspectionTemplatesData) {
      return [];
    }
    return inspectionTemplatesData.data;
  }, [inspectionTemplatesData]);

  const templatesGrouped = useMemo(() => {
    if (!selectedFilter) {
      return inspectionsStatus.map((status) => ({
        title: status.name,
        templates: templates.filter((template) => {
          const searchCondition = search
            ? template.name.toLowerCase().includes(search.toLowerCase())
            : true;
          return (
            template.status.toLowerCase() === status.id.toLowerCase() &&
            searchCondition
          );
        }),
      }));
    }
    return [
      {
        title: selectedFilter.name,
        templates: templates.filter((template) => {
          const searchCondition = search
            ? template.name.toLowerCase().includes(search.toLowerCase())
            : true;
          return (
            template.status.toLowerCase() === selectedFilter.id.toLowerCase() &&
            searchCondition
          );
        }),
      },
    ];
  }, [templates, selectedFilter, search]);

  return {
    selectedFilter,
    templates,
    templatesGrouped,
    isLoading,
    search,
    handleRefresh,
    handleSearch,
    handleSelectFilter,
    handleDeleteItem,
    handleCopyItem,
    handleView,
    handleEdit,
  };
};
