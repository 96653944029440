import { useCallback } from "react";
import { Row, Col, Button } from "reactstrap";
import InputEditable from "../InputEditable";
import { useFormOptions } from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

const FormTemplateOptionItem = ({ option, isReadOnly }) => {
  const { updateOptionContent, deleteOption } = useFormOptions();

  const handleUpdateContent = useCallback(($event) => {
    updateOptionContent(option.id, $event.target.value);
  }, [updateOptionContent, option]);

  const handleRemove = useCallback(() => {
    deleteOption(option.id);
  }, [deleteOption, option]);

  return (
    <Row className="option-item align-items-center mb-2">
      <Col md={1}>
        <Icon name="circle" />
      </Col>
      <Col md={5} className="justify-content-start">
        <InputEditable
          onBlur={handleUpdateContent}
          placeholder="Type an option"
          size="small"
          disabled={isReadOnly}
          defaultValue={option.content}
        />
      </Col>
      {!isReadOnly && (
        <Col md={1}>
          <Button
            size="sm"
            className="rounded-circle d-flex custom-rounded-button remove-btn border-0 font-size-1 py-1 px-2"
            color="white"
            onClick={handleRemove}
          >
            <Icon name="xmark" />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default FormTemplateOptionItem;
