import React, { useEffect, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import ExpenseModal from "./ExpenseModal";

import { useDeleteExpense } from "../api/Expenses.hooks";

const { ConfirmationModal, TooltipItem, Icon } = components;

const DECLINED = "declined";
const APPROVED = "approved";

const Expense = ({ expense, isReadOnly = false, onRefresh }) => {
  const [updateExpense, setUpdateExpense] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState();

  const { data: deleteExpenseData, deleteExpense } = useDeleteExpense();

  useEffect(() => {
    if (deleteExpenseData) {
      sharedHelper.successToast(`Expense deleted`);
      onRefresh();
    }
  }, [deleteExpenseData, onRefresh]);

  const onEdit = (expense, isReadOnly) =>
    setUpdateExpense({ ...expense, isReadOnly });

  const onDeleteExpense = () =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await deleteExpense(expense.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Expense",
      body: `Are you sure you want to delete this expense?`,
      confirmText: "Delete",
    });

  return (
    <div key={expense.id} className="col-12">
      <Card className="box-shadow-lg">
        <CardBody>
          <div className="d-flex align-items-start justify-content-between">
            <h4 className="currency">
              {sharedHelper.formatCurrency(expense.amount)}
            </h4>
            <div className="d-flex align-items-center gap-2">
              <div
                className="cursor-pointer"
                onClick={() => onEdit(expense, true)}
              >
                <Icon name="maximize-2" className="text-primary" />
              </div>
              <TooltipItem
                id="tooltip-expense-status"
                title={sharedHelper.capitalize(expense.status)}
              >
                {expense.status === APPROVED ? (
                  <Icon name="check" className="text-primary" />
                ) : expense.status === DECLINED ? (
                  <Icon name="x" className="text-danger" />
                ) : (
                  <Icon name="clock" className="text-muted" />
                )}
              </TooltipItem>
            </div>
          </div>
          <small className="text-muted">
            {expense.expenseType.name} with {expense.paymentType}
          </small>
          {!isReadOnly ? (
            <div className="mt-3 d-flex">
              <Button
                className="cursor-pointer border-radius-md"
                onClick={() => onDeleteExpense(expense)}
                data-testid="delete-button"
              >
                <Icon name="trash" className="ms-2 text-danger" />
              </Button>
              <Button
                color="primary"
                className="cursor-pointer ms-3 flex-grow-1"
                onClick={() => onEdit(expense)}
              >
                Edit
              </Button>
            </div>
          ) : null}
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : updateExpense ? (
        <ExpenseModal
          isReadOnly={updateExpense.isReadOnly}
          expense={updateExpense}
          onSubmit={() => {
            setUpdateExpense(false);
            sharedHelper.successToast(`Expenses saved`);
            onRefresh();
          }}
          onClose={() => setUpdateExpense(false)}
        />
      ) : null}
    </div>
  );
};

export default Expense;
