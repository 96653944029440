import { useState, useCallback, useRef, useMemo } from "react";
import { Row, Col, Button, Collapse } from "reactstrap";
import ReactQuill from "react-quill";
import { useGuidance } from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

const FormTemplateGuidance = ({ guidance, isReadOnly }) => {
  const fileInputRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [attachment, setAttachment] = useState(guidance.attachment);

  const {
    updateGuidanceText,
    addGuidanceAttachment,
    removeGuidanceAttachment,
  } = useGuidance();

  const handleCollapse = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleTextChange = useCallback(
    (value) => {
      updateGuidanceText(guidance.questionId, value);
    },
    [updateGuidanceText, guidance.questionId]
  );

  const handleAttachmentChange = useCallback(
    ($event) => {
      const file = $event.target.files[0];
      const updatedFile = new File([file], `guidance_${Date.now()}`, {
        type: file.type,
      });
      setAttachment(updatedFile);
      addGuidanceAttachment(guidance.questionId, updatedFile);
    },
    [addGuidanceAttachment, guidance.questionId]
  );

  const handleRemoveAttachment = useCallback(() => {
    setAttachment(null);
    removeGuidanceAttachment(guidance.questionId);
  }, [removeGuidanceAttachment, guidance.questionId]);

  const handleUploadAttachment = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const attachmentValue = useMemo(() => {
    if (!attachment) return null;
    return attachment instanceof File
      ? URL.createObjectURL(attachment)
      : attachment;
  }, [attachment]);

  return (
    <div className="border border-radius-md p-3 mx-0 my-3" sm={2}>
      <Row onClick={handleCollapse}>
        <Col sm={6}>
          <span className="fw-bold text-lg">Guidance</span>
        </Col>
        <Col className="d-flex justify-content-end" sm={6}>
          <Button className="btn btn-link d-flex align-items-center gap-2 expand-button text-decoration-none">
            <small className="text-primary fw-bold">
              View {isExpanded ? "less" : "more"}
            </small>
            <Icon
              name="chevron-right"
              className={`ms-2 rotate ${isExpanded ? "rotate-90" : ""}`}
            />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isExpanded}>
        <Row>
          <Col>
            <div className="mx-0 my-3">
              <Icon name="circle-info" />
              <span className="ms-2">Guidance text:</span>
            </div>
            <ReactQuill
              theme="snow"
              value={guidance.text}
              readOnly={isReadOnly}
              onChange={handleTextChange}
              className="border rounded"
            />
          </Col>
          <Col>
            <div className="mx-0 my-3">
              <Icon name="circle-info" />
              <span className="ms-2">Guidance attachment (jpg, png):</span>
            </div>
            <div>
              {!isReadOnly && (
                <input
                  type="file"
                  value=""
                  onChange={handleAttachmentChange}
                  disabled={isReadOnly}
                  className="d-none"
                  id="guidance-attachment"
                  accept="image/*"
                  name="guidance-attachment"
                  data-testid="guidance-attachment"
                  ref={fileInputRef}
                />
              )}
              {attachment ? (
                <div className="mt-2 d-flex align-items-start gap-2">
                  <img
                    src={attachmentValue}
                    alt="Guidance attachment preview"
                    className="img-fluid rounded"
                    style={{ maxWidth: "64px", maxHeight: "64px" }}
                  />
                  {!isReadOnly && (
                    <Button
                      color="link"
                      data-testid="remove-guidance-attachment"
                      className="rounded-circle d-flex custom-rounded-button text-danger border-0"
                      onClick={handleRemoveAttachment}
                    >
                      <Icon name="trash" />
                    </Button>
                  )}
                </div>
              ) : (
                !isReadOnly && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={handleUploadAttachment}
                  >
                    Upload Image
                  </Button>
                )
              )}
            </div>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default FormTemplateGuidance;
