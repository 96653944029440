import { Row, CardBody } from "reactstrap";
import React from "react";

const ListTemplatesGrouped = ({
  title,
  hasTemplates,
  children,
}) => {
  return (
    <CardBody className="section-body">
      <div className="font-size-1-2 fw-bold">{title}</div>
      {hasTemplates ? (
        <Row className="mt-3" xxl={4} xl={3} lg={3} md={3} sm={2} xs={1}>
          {children}
        </Row>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100 text-black-50">
          No templates found with {title.toLowerCase()} status
        </div>
      )}
    </CardBody>
  );
};

export default ListTemplatesGrouped;
