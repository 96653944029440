import FormTemplateOptions from "./FormTemplateOptions";

const QUESTION_TYPES = {
  TEXT_FIELD: "TEXT_FIELD",
  DATE_FIELD: "DATE_FIELD",
  MULTIPLE_CHOICE_FIELD: "MULTIPLE_CHOICE_FIELD",
  CHECKBOXES_FIELD: "CHECKBOXES_FIELD",
};

const FormTemplateQuestionTypePreview = ({ question, options, isReadOnly }) => {
  switch (question.questionType?.slug) {
    case QUESTION_TYPES.TEXT_FIELD:
      return <textarea disabled rows={4} type="text" maxLength="255" className="form-control" placeholder="This is a text input example, short or long." />;
    case QUESTION_TYPES.DATE_FIELD:
      return <input type="date" disabled className="form-control" />;
    case QUESTION_TYPES.MULTIPLE_CHOICE_FIELD:
      return <FormTemplateOptions options={options} questionId={question.id} isReadOnly={isReadOnly} />;
    case QUESTION_TYPES.CHECKBOXES_FIELD:
      return <FormTemplateOptions options={options} questionId={question.id} isReadOnly={isReadOnly} />;
    default:
      return null;
  }
};

export default FormTemplateQuestionTypePreview;
