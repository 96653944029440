import { useMemo, useCallback, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useBlocker } from "react-router-dom";

const BlockNavigation = ({
  title,
  content,
  isBlocked,
  mainButtonText,
  secondaryButtonText,
  onClickMainButton,
  onClickSecondaryButton,
}) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked && currentLocation.pathname !== nextLocation.pathname
  );

  const isOpen = useMemo(() => blocker.state === "blocked", [blocker.state]);

  const handleContinue = useCallback(() => {
    blocker.proceed();
    if (onClickSecondaryButton) {
      onClickSecondaryButton();
    }
  }, [blocker, onClickSecondaryButton]);

  const handleSave = useCallback(() => {
    blocker.reset();
    if (onClickMainButton) {
      onClickMainButton();
    }
  }, [blocker, onClickMainButton]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (!isBlocked) {
        blocker.proceed();
      }
    }
  }, [blocker, isBlocked]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader className="border-bottom-0">{title}</ModalHeader>
      <ModalBody>
        <p>{content}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleContinue}>
          {secondaryButtonText}
        </Button>
        <Button color="danger" onClick={handleSave}>
          {mainButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BlockNavigation;
