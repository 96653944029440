import { useCallback } from "react";
import { Row, Col, Button } from "reactstrap";
import FormTemplateOptionItem from "./FormTemplateOptionItem";
import { useFormOptions } from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

const FormTemplateOptions = ({ options, questionId, isReadOnly }) => {
  const { addOption } = useFormOptions();

  const handleAddOption = useCallback(() => {
    addOption(questionId);
  }, [addOption, questionId]);

  return (
    <div className="options-list">
      {options.map((option) => (
        <FormTemplateOptionItem
          option={option}
          key={option.id}
          isReadOnly={isReadOnly}
        />
      ))}
      {!isReadOnly && (
        <Row className="add-btn-container m-0">
          <Col className="d-grid p-0" md={7}>
            <Button
              size="sm"
              color="white"
              className="btn-dashed py-2 px-4 justify-content-center font-size-1 rounded-4"
              onClick={handleAddOption}
            >
              <Icon name="circle-plus" className="width-50" /> Add Option
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FormTemplateOptions;
