import { useContext, useCallback } from "react";
import { v1 as uuidv1 } from "uuid";
import { ELEMENT_STATUS, FormTemplateContext } from "../index";
import ACTIONS from "../actions";
import useOptions from "./options.hook";

const useQuestions = () => {
  const context = useContext(FormTemplateContext);
  const { state, dispatch, getQuestions, getOptions, getDefaultQuestionType } =
    context;
  const { copyOption, deleteOptions } = useOptions();

  const addQuestion = useCallback(
    (groupId) => {
      const questions = getQuestions(groupId);
      const defaultQuestionType = getDefaultQuestionType();
      const question = {
        id: uuidv1(),
        content: "",
        status: ELEMENT_STATUS.CREATE,
        order: questions.length + 1,
        questionType: defaultQuestionType,
        questionTypeId: defaultQuestionType?.id,
        groupId,
      };
      console.log("question", question);
      dispatch({ type: ACTIONS.ADD_QUESTION, payload: question });
    },
    [dispatch, getDefaultQuestionType, getQuestions]
  );

  const updateQuestionName = useCallback(
    (id, content) => {
      const isNewQuestion =
        state.questions[id] &&
        state.questions[id].status === ELEMENT_STATUS.CREATE;
      const status = isNewQuestion
        ? ELEMENT_STATUS.CREATE
        : ELEMENT_STATUS.UPDATE;
      if (content === state.questions[id].content) {
        return;
      }
      dispatch({
        type: ACTIONS.UPDATE_QUESTION_NAME,
        payload: { id, content, status },
      });
    },
    [dispatch, state.questions]
  );

  const updateQuestionsOrder = useCallback(
    (questions) => {
      const questionsReduced = questions.reduce((acc, question, index) => {
        acc[question.id] = {
          ...question,
          order: index + 1,
          status:
            question.status !== ELEMENT_STATUS.CREATE
              ? ELEMENT_STATUS.UPDATE
              : question.status,
        };
        return acc;
      }, {});
      dispatch({
        type: ACTIONS.UPDATE_QUESTIONS_ORDER,
        payload: questionsReduced,
      });
    },
    [dispatch]
  );

  const deleteQuestion = useCallback(
    (id) => {
      const questions = { ...state.questions };
      const question = questions[id];
      const groupId = question.groupId;
      const isNewQuestion =
        question && question.status === ELEMENT_STATUS.CREATE;
      if (isNewQuestion) {
        delete questions[id];
      } else {
        question.status = ELEMENT_STATUS.DELETE;
      }
      const reorderedQuestions = Object.values(questions)
        .filter(
          (question) =>
            question.groupId === groupId &&
            question.status !== ELEMENT_STATUS.DELETE
        )
        .sort((a, b) => a.order - b.order)
        .reduce((acc, question, index) => {
          acc[question.id] = {
            ...question,
            order: index + 1,
            status:
              question.status !== ELEMENT_STATUS.CREATE
                ? ELEMENT_STATUS.UPDATE
                : question.status,
          };
          return acc;
        }, {});
      const options = getOptions(id);
      deleteOptions(options);
      dispatch({
        type: ACTIONS.DELETE_QUESTION,
        payload: { ...questions, ...reorderedQuestions },
      });
    },
    [dispatch, state.questions, deleteOptions, getOptions]
  );

  const deleteQuestions = useCallback(
    (questions) => {
      const questionsMap = { ...state.questions };
      const options = questions
        .map((question) => getOptions(question.id))
        .flat();
      deleteOptions(options);
      questions.forEach((question) => {
        const isNewQuestion =
          questionsMap[question.id] &&
          questionsMap[question.id].status === ELEMENT_STATUS.CREATE;
        if (isNewQuestion) {
          delete questionsMap[question.id];
        } else {
          questionsMap[question.id].status = ELEMENT_STATUS.DELETE;
        }
      });
      dispatch({ type: ACTIONS.DELETE_QUESTION, payload: { ...questionsMap } });
    },
    [dispatch, state.questions, deleteOptions, getOptions]
  );

  const copyQuestion = useCallback(
    (question, { keepOrder = false } = {}) => {
      const questions = getQuestions(question.groupId);
      const newQuestion = {
        ...question,
        id: uuidv1(),
        status: ELEMENT_STATUS.CREATE,
        order: keepOrder ? question.order : questions.length + 1,
      };
      getOptions(question.id).map((option) =>
        copyOption(
          { ...option, questionId: newQuestion.id },
          { keepOrder: true }
        )
      );
      dispatch({ type: ACTIONS.COPY_QUESTION, payload: newQuestion });
    },
    [dispatch, copyOption, getOptions, getQuestions]
  );

  const updateQuestionType = useCallback(
    (id, questionTypeId) => {
      const isNewQuestion =
        state.questions[id] &&
        state.questions[id].status === ELEMENT_STATUS.CREATE;
      const status = isNewQuestion
        ? ELEMENT_STATUS.CREATE
        : ELEMENT_STATUS.UPDATE;
      const questionType = state.questionTypes.find(
        (questionType) => questionType.id === questionTypeId
      );
      deleteOptions(getOptions(id));
      dispatch({
        type: ACTIONS.UPDATE_QUESTION_TYPE,
        payload: { id, questionType, status },
      });
    },
    [dispatch, state.questions, state.questionTypes, deleteOptions, getOptions]
  );

  const updateQuestionRequired = useCallback(
    (id, isRequired) => {
      const isNewQuestion =
        state.questions[id] &&
        state.questions[id].status === ELEMENT_STATUS.CREATE;
      const status = isNewQuestion
        ? ELEMENT_STATUS.CREATE
        : ELEMENT_STATUS.UPDATE;
      dispatch({
        type: ACTIONS.UPDATE_QUESTION_REQUIRED,
        payload: { id, isRequired, status },
      });
    },
    [dispatch, state.questions]
  );

  const addGuidance = useCallback(
    (questionId) => {
      const guidance = state.questions[questionId].guidance || {};
      dispatch({
        type: ACTIONS.ADD_GUIDANCE,
        payload: {
          questionId,
          guidance: {
            ...guidance,
            questionId,
            status:
              guidance.status === ELEMENT_STATUS.DELETE
                ? ELEMENT_STATUS.UPDATE
                : ELEMENT_STATUS.CREATE,
          },
        },
      });
    },
    [dispatch, state.questions]
  );

  const deleteGuidance = useCallback(
    (questionId) => {
      const guidance = state.questions[questionId].guidance || {};
      const guidanceData =
        guidance.status === ELEMENT_STATUS.CREATE || !guidance.status
          ? null
          : {
              ...guidance,
              status: ELEMENT_STATUS.DELETE,
            };
      dispatch({
        type: ACTIONS.DELETE_GUIDANCE,
        payload: {
          questionId,
          guidance: guidanceData,
        },
      });
    },
    [dispatch, state.questions]
  );

  return {
    ...context,
    addQuestion,
    updateQuestionName,
    updateQuestionsOrder,
    deleteQuestion,
    copyQuestion,
    updateQuestionType,
    updateQuestionRequired,
    addGuidance,
    deleteGuidance,
    deleteQuestions,
  };
};

export default useQuestions;
