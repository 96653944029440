const { sharedHelper } = require("@crewos/shared");

const SMART_REPORTS_READ_ONLY = "SMART_REPORTS_READ_ONLY";

const REPORTS_PACKAGE = "reports";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

export const getColumns = (report) =>
  report.columns
    .filter((column) => column.enabled)
    .map((column) => ({
      disableSortBy: true,
      accessor: column.accessor,
      header: column.label,
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      type: ["NUMBER", "PERCENTAGE", "CURRENCY"].includes(column.type)
        ? "number"
        : "text",
      Cell: (rowData) => {
        let data = rowData.row[column.accessor];
        switch (column.type) {
          case "NUMBER":
            if (!isNaN(data)) {
              data = data.toFixed(2);
            }
            break;
          case "PERCENTAGE":
            if (!isNaN(data)) {
              data = `${data.toFixed(2)}%`;
            }
            break;
          case "CURRENCY":
            if (!isNaN(data)) {
              data = sharedHelper.formatCurrency(data);
            }
            break;
          case "DATE":
            data = sharedHelper.formatDateTimes(rowData.row[column.accessor]);
            break;
          case "DATETIME":
            data = sharedHelper.formatDateTimes(rowData.row[column.accessor]);
            break;
          default:
            break;
        }
        return data || "-";
      },
    }));

export const isSmartReportsReadOnly = (userData) => {
  const smartReportsReadOnlyEnabled = sharedHelper.isSettingEnabled(
    userData?.packages,
    REPORTS_PACKAGE,
    SMART_REPORTS_READ_ONLY
  );
  const isSuperAdmin = sharedHelper.userMeetsRole(userData, [
    IS_SUPER_ADMIN_USER,
  ]);
  const isReadOnly = smartReportsReadOnlyEnabled && !isSuperAdmin;
  return isReadOnly;
};
