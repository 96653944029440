import React, { useCallback, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import FormTemplateSectionItem from "./FormTemplateSectionItem";
import SortableWrapper from "../SortableWrapper";
import { useFormSections, useFormTemplate } from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

const FormTemplateSections = ({ isReadOnly, sections, ConfigurationTemplateModal }) => {
  const [isOpenConfiguration, setIsOpenConfiguration] = useState(false);
  const { updateSectionsOrder, addSection } = useFormSections();
  const { setConfiguration } = useFormTemplate();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active?.id !== over?.id) {
        const oldIndex = sections.findIndex(
          (section) => section.id === active.id
        );
        const newIndex = sections.findIndex(
          (section) => section.id === over.id
        );
        const newSections = arrayMove(sections, oldIndex, newIndex);
        console.log("newSections", newSections);
        updateSectionsOrder(newSections);
      }
    },
    [updateSectionsOrder, sections]
  );

  const handleOpenConfigurationModal = useCallback(() => {
    setIsOpenConfiguration(prev => !prev);
  }, []);

  const handleClosConfigurationModal = useCallback(() => {
    setIsOpenConfiguration(prev => !prev);
  }, []);

  const handleSaveConfiguration = useCallback((data) => {
    setConfiguration(data);
    handleClosConfigurationModal();
  }, [setConfiguration, handleClosConfigurationModal]);

  return (
    <Row className="border-bottom-0 mb-0 gap-3" sm={1}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={sections.map((section) => section.id)}
          strategy={verticalListSortingStrategy}
        >
          {sections.map((section) => (
            <SortableWrapper
              isReadOnly={isReadOnly}
              key={section.id}
              id={section.id}
              className="nav-item rounded-4 d-flex align-items-center gap-4 px-3 py-2"
            >
              <FormTemplateSectionItem section={section} isReadOnly={isReadOnly} />
            </SortableWrapper>
          ))}
          {!isReadOnly && (
            <Col className="nav-item p-0 d-flex gap-2">
              <Button
                size="sm"
                className="py-2 px-3 btn-text rounded-4 rounded-end-0 w-100 text-start"
                color="secondary"
                onClick={handleOpenConfigurationModal}
              >
                <Icon name="settings" /> Configurations
              </Button>
              <Button
                size="sm"
                className="py-2 px-3 btn-text rounded-4 rounded-end-0 w-100 text-end"
                color="secondary"
                onClick={addSection}
              >
                <Icon name="plus-circle" /> Add Section
              </Button>
            </Col>
          )}
        </SortableContext>
      </DndContext>
      {ConfigurationTemplateModal && (
        <ConfigurationTemplateModal isOpen={isOpenConfiguration} onClose={handleClosConfigurationModal} onSave={handleSaveConfiguration} />
      )}
    </Row>
  );
};

export default FormTemplateSections;
